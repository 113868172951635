import React from "react";
import About from "./Components/About";
import Education from "./Components/Education";
import Home from "./Components/Home";
import Header from "./Components/Header"
import Experience from "./Components/Experience";
import Contact from "./Components/Contact";
import Project from "./Components/Project"
import Orgs from "./Components/Orgs"
import Skills from "./Components/Skills"
import Teaching from "./Components/Teaching"
import RNavbar from "./Components/Navbar";
//import "/Users/festobwogi/Desktop/Personal Website/src/styles/styles.css"
import "./styles/styles.css"
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from 'react-router-dom';



export default function App() {


  return (

    <main className="app" style={{backgroundColor: 'skyblue'}}>
      <Router >
        <RNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Education" element={<Education />} />
          <Route path="/Experience" element={<Experience />} />
          <Route path="/Teaching" element={<Teaching />} />
          <Route path="/Experience" element={<Experience />} />
          <Route path="/Projects" element={<Project />}/>
          <Route path="/Skills" element={<Skills />} />
          <Route path="/Orgs" element={<Orgs />} />




        </Routes>
      </Router>
      <Contact />


    
    </main>
  );
}
