import React from "react";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { MdWork } from 'react-icons/md'; // Import a suitable icon

export default function Teaching() {
  const teStyle = {
    backgroundColor: 'black', 
    fontFamily: 'Josefin Slab',
    color: 'white'
  };
    return (
      <section id="teaching" style={teStyle}>

        <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-left">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-left">
          <h1 style={{fontFamily: 'cursive', textDecorationLine: 'underline'}}> Teaching Experience</h1>
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<MdWork />}
              contentStyle={{ background: 'white', color: 'black' }}
              contentArrowStyle={{ borderRight: '7px solid white' }}
            >
           
            <h3> Private Tutor </h3>
              <h5> 2015 - present </h5>
                <li><ul> Held individual and group tutoring sessions for students from kindergarten to college  </ul> </li>

                <li><ul> Tutor students in all levels of computer science, electrical engineering, math, science, English, and more  </ul> </li>
                  

                <li><ul> Assess students' learning styles and create study plans for them  </ul></li>
                <li><ul> Give students and parents feedback on students' progress  </ul></li>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<MdWork />}
              contentStyle={{ background: 'white', color: 'black' }}
              contentArrowStyle={{ borderRight: '7px solid white' }}
            >
            <h2> America on Tech </h2>
            <h3> Web Development Instructor </h3>
              <h5> Summer 2023 </h5>

                  <li><ul> Taught high school students how to create websites using HTML, CSS and Bootstrap. </ul></li>
                  <li><ul> Assessed student projects and assignments using AOT-developed rubrics. </ul></li>
                  <li><ul> Reviewed curriculum and prepared lessons, adjusting lessons when necessary. </ul></li>
                  <li><ul> Regularly met with the Site Coordinators and Program Managers to discuss program impact and to discuss any challenges. </ul></li>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<MdWork />}
              contentStyle={{ background: 'white', color: 'black' }}
              contentArrowStyle={{ borderRight: '7px solid white' }}
            >          
            <h2> YMCA Community Center at Home Gardens Library</h2>
            <h3> Volunteer Tutor and Mentor  </h3>
              <h5> Summer 2014 - Summer 2016 </h5>
                <li><ul> Tutored students from grades K-12 in math, science and language arts  </ul></li>

                <li><ul> Worked one on one with students to assess students' learning styles and create study plans for them  </ul></li>
                <li><ul> Helped supervise students and create daily activities  </ul></li>
                </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<MdWork />}
              contentStyle={{ background: 'white', color: 'black' }}
              contentArrowStyle={{ borderRight: '7px solid white' }}
            >     
            <h2> Corona Public Library</h2>
            <h3> Volunteer Tutor </h3>
              <h5> September 2008 - May 2015 </h5>
                <li><ul> Tutored students from grades K-6 in math, science and language arts  </ul></li>

                <li><ul> Worked one on one with students to assess students' learning styles and help them understand their schoolwork  </ul></li>
                <li><ul> Gave parents feedback on their child's progress  </ul></li>
                </VerticalTimelineElement>

            
          </VerticalTimeline>

          </div>
        </div>
      
      </section>
    );
    
};