import React from 'react';
import "/Users/festobwogi/Desktop/Personal Website/src/styles/styles.css"
import { SocialIcon } from 'react-social-icons'
//import "./styles/styles.css"

import backgroundImage from "/Users/festobwogi/Desktop/Personal Website/src/images/image.jpeg";
//import backgroundImage from "./images/image.jpeg"

export default function Header() {
    const headerStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover", // or 'contain' depending on your needs
        backgroundPosition: "center",
        height: "500px", // Adjust the height as needed
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
        color: "white" // If you want to add text and need to change its color
      };
    
    return (
  
      <header style={headerStyle}>
          <h3 style={{color: 'white', marginTop:'50px'}}>
            {" "}
            Computer Science and Engineering | Data Science | IT Solutions | IT Management{" "}
          </h3>
          <div className="personal-links">
                <p>
                  <SocialIcon url="https://www.linkedin.com/in/festo-bwogi/" target="_blank" rel="noopener noreferrer" />
                  <SocialIcon url="https://github.com/fbwogi" target="_blank" rel="noopener noreferrer" />
                  <SocialIcon url="https://www.instagram.com/fes.tech/?igsh=YTQwZjQ0NmI0OA%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer" />

                </p>
                

            </div>
      </header>
      
    );
};