import React from "react";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { MdWork } from 'react-icons/md'; // Import a suitable icon

export default function Experience() {
  const exStyle = {
    backgroundColor: 'black',
    fontFamily: 'Josefin Slab',
    color: 'white',
  };

  return (
    <section id="experience" style={exStyle}>
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-left">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-left text-left">
          <h1 style={{ fontFamily: 'cursive', textDecorationLine: 'underline' }}>Experience</h1>
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<MdWork />}
              contentStyle={{ background: 'white', color: 'black' }}
              contentArrowStyle={{ borderRight: '7px solid white' }}
            >
              <h3 className="vertical-timeline-element-title">IT Specialist</h3>
              <h4 className="vertical-timeline-element-subtitle">Bwogi and Associates</h4>
              <h5>January 2020 - Present</h5>
              <ul>
               <li> Diagnosing client’s software and hardware issues. </li>
               <li> Running shell scripts to fix software issues. </li>
               <li> Assisting clients in software updates and downloads. </li>
               <li> Providing clients with data-driven solutions. </li>
               <li>
                 {" "}
                 Create web applications using APIs with Python and Java.{" "}
               </li>
             </ul>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: 'rgb(10, 10, 10)', color: '#fff' }}
              icon={<MdWork />}
              contentStyle={{ background: 'white', color: 'black' }}
              contentArrowStyle={{ borderRight: '7px solid white' }}
            >
              <h3 className="vertical-timeline-element-title">Graduate Assistant</h3>
              <h4 className="vertical-timeline-element-subtitle">California State University, Fullerton</h4>
              <h5>February 2023 - June 2023</h5>

              <ul>
               <li>
                 {" "}
                 Performing data mining and analysis tasks with Python.{" "}
               </li>
               <li>
                 {" "}
                 Coding web scrapers for social media sites with Python.{" "}
               </li>
               <li>
                 {" "}
                 Consulting Marketing Professor Reshadi on programming aspects
                 of her research.{" "}
               </li>
             </ul>
           </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
              icon={<MdWork />}
              contentStyle={{ background: 'white', color: 'black' }}
              contentArrowStyle={{ borderRight: '7px solid white' }}
            >
              <h3 className="vertical-timeline-element-title">Backend Engineer, Integrations</h3>
              <h4 className="vertical-timeline-element-subtitle">Webstacks</h4>
              <h5>February 2022 - April 2022</h5>

              <ul>
               <li>
                 {" "}
                 Provided code reviews, refactor codebases and debug code.{" "}
               </li>
               <li>
                 {" "}
                 Created scalable web applications on the server-side using
                 Node.JS and Python.{" "}
               </li>
               <li> Integrated data into Hubspot CRM. </li>
               <li> Maintained and update MongoDB and client databases. </li>
             </ul>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
              icon={<MdWork />}
              contentStyle={{ background: 'white', color: 'black' }}
              contentArrowStyle={{ borderRight: '7px solid white' }}
            >
              <h3 className="vertical-timeline-element-title">Software Developer</h3>
              <h4 className="vertical-timeline-element-subtitle">Dropplugg Inc.</h4>
              <h5>August 2021 - February 2022</h5>

              <ul>
               <li>
                 {" "}
                 Designed and implement database schemas and API endpoints.
               </li>
               <li>
                 {" "}
                 Configured deployment and local development using Heroku and
                 Netlify.{" "}
               </li>
               <li>
                 {" "}
                 Built web applications using MERN stack, Python and Java.{" "}
               </li>
               <li>
                 {" "}
                 Created data visualizations using Python libraries NumPy and
                 Pandas.{" "}
               </li>
             </ul>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
      </div>
      <br />
    </section>
  );
}
