import React from 'react';

import Header from "./Header"
import About from './About';

function Home() {
  return (
    <>
      <Header />
      <About />
    </>
  );
}

export default Home;