import React from 'react';
import "/Users/festobwogi/Desktop/Personal Website/src/styles/styles.css"
//import "./styles/styles.css"

export default function Contact() {
    
    return (
        <footer className="site-footer">
        <div className="footer-content">
            <div className="contact-us">
                <h3>Contact </h3>
                <p>Email: festobwogi@gmail.com</p>
                <p>Address: Los Angeles, CA</p>
            </div>
            
        </div>
    </footer>
    );
}


