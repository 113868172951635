import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LinkContainer } from 'react-router-bootstrap';

function RNavbar() {
  return (
    <Navbar collapseOnSelect expand="lg" bg="blue" variant="dark" className="bg-blue">
      <Container>
        <Navbar.Brand href="#home" style={{ color: '#000000'}}>Festo Jesse Bwogi, M.S.</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/education">
              <Nav.Link>Education</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/experience">
              <Nav.Link>Experience</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/teaching">
              <Nav.Link>Teaching</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/projects">
              <Nav.Link>Projects</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/skills">
              <Nav.Link>Skills</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/orgs">
              <Nav.Link>Organizations</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default RNavbar;

