import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain, faCloud, faDatabase, faServer, faCode, faSearch, faMicrochip, faLanguage } from '@fortawesome/free-solid-svg-icons';

const Section = styled.section`
  padding: 20px;
  background-color: black;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
`;

const InfoContainer = styled.div`
  flex: 1;
  padding-right: 24px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  text-align: center;

  @media (min-width: 768px) {
    text-align: right;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: 500;
  color: white;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 4rem;
  }
`;

const Description = styled.p`
  margin-bottom: 2rem;
  font-size: 1.25rem;
  color: white;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
`;
const List = styled.ul`
  list-style-type: none;  // Remove default list styling
  padding: 0;
`;

const ListItem = styled.li`
  color: white;
  margin-bottom: 0.5rem; // Space between items
  &:before {
    content: ""; // We'll use FontAwesome icons instead of content
    display: inline-block;
    width: 20px; // Ensure sufficient space for icons
  }
`;

function About() {
  return (
    <Section id="about">
      <Container>
        <InfoContainer>
          <p style={{color: 'white'}}>Driven by a deep passion for technology and business, I present an extensive background as a freelance IT Specialist. My expertise covers software engineering and data science, that has been refined by years of academic, professional and personal work and dedication. This has led me to develop a profound interest in specific areas, including:</p>
          <List>
            <ListItem><FontAwesomeIcon icon={faBrain} /> Artificial Intelligence/Machine Learning</ListItem>
            <ListItem><FontAwesomeIcon icon={faCloud} /> Cloud Computing</ListItem>
            <ListItem><FontAwesomeIcon icon={faDatabase} /> Data Science</ListItem>
            <ListItem><FontAwesomeIcon icon={faServer} /> Data Engineering</ListItem>
            <ListItem><FontAwesomeIcon icon={faDatabase} /> Databases</ListItem>
            <ListItem><FontAwesomeIcon icon={faSearch} /> Data Mining</ListItem>
            <ListItem><FontAwesomeIcon icon={faMicrochip} /> Embedded Systems</ListItem>
            <ListItem><FontAwesomeIcon icon={faLanguage} /> Natural Language Processing</ListItem>
          </List>
        </InfoContainer>
      </Container>
    </Section>
  );
}

export default About;
