import React from "react";

export default function Project() {
  
  const orStyle = {
    backgroundColor: 'black', 
    fontFamily: 'Josefin Slab',
    color: 'white'
  };
  return (
    <div style={orStyle} className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-left">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-left">


      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-left">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-left">
          <h1 style={{fontFamily: 'cursive', textDecorationLine: 'underline'}}> Organizations/Affiliations </h1>
            <h2> <a href= "http://www.nsbe.org" target = "_blank">National Society of Black Engineers </a></h2>
              <h5> Member: 2015-present              </h5>
              <h3> Positions </h3>
              <h4 style={{textDecorationLine: 'underline'}}> CSU Fullerton NSBE Chapter </h4>
                <h5> President: 2023-present 
                   <li><ul> Co-hosted events to help promote study habits for K-12 and university students. </ul></li>
                  <li><ul> Spearhead projects that will help build members' portfolios. </ul></li>
                  <li><ul> Helped build internal and external support for the chapter. </ul></li>

                </h5>
                
              <h4 style={{textDecorationLine: 'underline'}}> UC Riverside NSBE Chapter </h4> 

                <h5> Vice President: 2018-2019 
                  <li><ul> Co-hosted events to help promote study habits for K-12 and university students. </ul></li>
                  <li><ul> Helped build internal and external support for the chapter. </ul></li>
                </h5>
                <h5> Webmaster: 2017-2018 
                  <li><ul> Maintained the NSBE site with BCOE. </ul></li>
                </h5>
            <br />   
            <h3> <a href= "https://www.lablackengineers.org">Los Angeles Council of Black Professional Engineers </a></h3>
              <h5> Member: 2023-present </h5>
        </div>
      </div>
    </div>
    </div>
  );
}